<template>
  <van-popup
    v-model="isShowPopup"
    :safe-area-inset-bottom="false"
    class="type-popup"
  >
    <div class="type-item" @click="onCreateTask('once')">
      <img src="~@/assets/img/task/icon_once.png" class="type-icon" />
      <div class="type-title">单次任务</div>
      <div class="type-describe">单次执行的任务，只需要执行一次</div>
    </div>
    <div class="gap"></div>
    <div class="type-item" @click="onCreateTask('period')">
      <img src="~@/assets/img/task/icon_period.png" class="type-icon" />
      <div class="type-title">周期性任务</div>
      <div class="type-describe">
        按月、按周执行的任务，具有周期性，在任务截止时间前要执行多次
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  data() {
    return {
      isShowPopup: false
    }
  },

  methods: {
    onCreateTask(type) {
      const tenantId = global.APP_CONFIG.tenantId
      this.$router.push(`/task/create/${tenantId}/${type}`)
    },

    onOpen() {
      this.isShowPopup = true
    }
  }
}
</script>

<style lang="less" scoped>
.type-popup {
  width: 277px;
  height: 430px;
  box-sizing: border-box;
  padding: 37px 28px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .gap {
    height: 1px;
    background-color: #e8e8e8;
    width: 100%;
    margin: 30px 0;
  }
  .type-item {
    text-align: center;
    flex: 1;
    .type-icon {
      width: 72px;
      height: 72px;
    }
    .type-title {
      font-family: PingFangSC-Medium;
      font-weight: 500;
      font-size: 16px;
      color: #333333;
      margin: 18px 0 8px 0;
    }
    .type-describe {
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
    }
  }
}
</style>
