<template>
  <van-popup
    v-model="isShowPopup"
    :safe-area-inset-bottom="false"
    get-container="body"
    position="bottom"
  >
    <div class="popup-title">
      <div class="btn cancel" @click="onReset">重置</div>
      <div class="title">选择条件进行筛选</div>
      <div class="btn submit" @click="onSubmit">确定</div>
    </div>
    <div class="param-list">
      <van-list>
        <van-cell
          title-class="param-title"
          value-class="param-value"
          title="创建时间范围"
          :value="cmpDateRange"
          is-link
          @click="onOpenCalendar"
        />
        <van-cell
          title-class="param-title"
          value-class="param-value"
          title="任务创建人"
          :value="cmpCreator"
          is-link
          @click="onOpenCreatorPerson"
        />
        <van-cell
          title-class="param-title"
          value-class="param-value"
          title="任务名称"
          :value="filterParams.taskName"
          is-link
          @click="onOpenInput('taskName')"
        />
        <van-cell
          title-class="param-title"
          value-class="param-value"
          title="任务内容"
          :value="filterParams.taskDetail"
          is-link
          @click="onOpenInput('taskDetail')"
        />
        <van-cell
          title-class="param-title"
          value-class="param-value"
          title="任务执行人"
          :value="cmpExecutor"
          is-link
          @click="onOpenExecutorPerson"
        />
      </van-list>
      <filter-calendar ref="refCalendar" @on-change="onChangeDatetime" />
      <filter-person ref="refCreatorPerson" @on-change="onChangeCreator" />
      <filter-person ref="refExecutorPerson" @on-change="onChangeExecutor" />
      <filter-input ref="refInput" :input-content="inputContent" :input-type="inputType" @on-change="onChangeInput" />
    </div>
  </van-popup>
</template>

<script>
import FilterCalendar from './filter-calendar.vue'
import FilterPerson from './filter-person.vue'
import FilterInput from './filter-input.vue'
import moment from 'moment'

export default {
  components: { FilterCalendar, FilterPerson, FilterInput },

  data() {
    return {
      isShowPopup: false,
      filterParams: {
        createTimeEnd: '',
        createTimeStart: '',
        creatorIds: [],
        executorIds: [],
        taskDetail: '',
        taskName: ''
      },
      creators: [],
      executors: [],
      inputType: 'taskName',
      inputContent: ''
    }
  },

  computed: {
    cmpDateRange() {
      if (
        this.filterParams.createTimeStart &&
        this.filterParams.createTimeEnd
      ) {
        return (
          moment(this.filterParams.createTimeStart).format('YYYY.MM.DD') +
          '-' +
          moment(this.filterParams.createTimeEnd).format('YYYY.MM.DD')
        )
      }
      return ''
    },

    cmpCreator() {
      return this.creators.length <= 3 ? this.creators.map(v => v.name).join() : this.creators.map(v => v.name).join() + '...等' + this.creators.length + '人'
    },

    cmpExecutor() {
      return this.executors.length <= 3 ? this.executors.map(v => v.name).join() : this.executors.map(v => v.name).join() + '...等' + this.executors.length + '人'
    }
  },

  methods: {
    onReset() {
      this.filterParams = this.$options.data().filterParams
      this.creators = []
      this.executors = []
      this.$refs.refCreatorPerson.selected = []
      this.$refs.refExecutorPerson.selected = []
    },

    onSubmit() {
      const params = {
        ...this.filterParams,
        createTimeStart: this.filterParams.createTimeStart ? moment(this.filterParams.createTimeEnd).startOf('D').valueOf() : '',
        createTimeEnd: this.filterParams.createTimeEnd ? moment(this.filterParams.createTimeEnd).endOf('D').valueOf() : ''
      }
      this.$emit('on-change', params)
      this.isShowPopup = false
    },

    onOpenInput(inputType) {
      this.inputType = inputType
      this.inputContent = this.filterParams[this.inputType]
      this.$refs.refInput.onOpen()
    },

    onChangeInput(data) {
      Object.assign(this.filterParams, data)
    },

    // 时间改变事情
    onChangeDatetime(date) {
      const { start, end } = date
      this.filterParams.createTimeStart = new Date(start)
      this.filterParams.createTimeEnd = new Date(end)
    },

    // 打开人员
    onOpenCreatorPerson() {
      this.$refs.refCreatorPerson.onOpen()
    },

    // 接收创建人
    onChangeCreator(data) {
      this.creators = data
      this.filterParams.creatorIds = data.map(k => k.userId)
    },

    // 打开执行人
    onOpenExecutorPerson() {
      this.$refs.refExecutorPerson.onOpen()
    },

    // 接收执行人
    onChangeExecutor(data) {
      this.executors = data
      this.filterParams.executorIds = data.map(k => k.userId)
    },

    // 打开日期
    onOpenCalendar() {
      this.$refs.refCalendar.onOpen()
    },

    onOpen() {
      this.isShowPopup = true
    }
  }
}
</script>

<style lang="less" scoped>
.popup-title {
  display: flex;
  justify-content: space-between;
  line-height: 54px;
  box-sizing: border-box;
  padding: 0 16px;

  .title {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #333333;
  }
  .btn {
    font-size: 14px;
    &.cancel {
      color: #999999;
    }
    &.submit {
      font-family: PingFangSC-Medium;
      color: #287de1;
    }
  }
}
.param-list {
  padding-bottom: 44px;

  .param-title {
    font-size: 14px;
    color: #999999;
  }
  .param-value {
    font-size: 14px;
    color: #333333;
  }
  /deep/ .van-cell__right-icon {
    color: #333;
  }
}
</style>
