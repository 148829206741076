<template>
  <div class="task-wrap">
    <div>
      <van-tabs
        v-model="activeName"
        :lazy-render="false"
        color="#287DE1"
        sticky
      >
        <template slot="nav-right">
          <div class="tab-btn" @click="onOpenFilter">
            <img src="~@/assets/img/task/icon_filter.png" class="icon-filter" />
          </div>
        </template>
        <van-tab :title="`未完成(${taskTotal.undo})`" :name="0">
          <task-list
            :task-status="1"
            :filter-params="filterParams"
            :active="activeName"
            @change-total="onChangeTotal"
          />
        </van-tab>
        <van-tab :title="`已完成(${taskTotal.finished})`" :name="1">
          <task-list
            :task-status="2"
            :filter-params="filterParams"
            :active="activeName"
            @change-total="onChangeTotal"
          />
        </van-tab>
        <van-tab :title="`已过期(${taskTotal.overdue})`" :name="2">
          <task-list
            :task-status="3"
            :filter-params="filterParams"
            :active="activeName"
            @change-total="onChangeTotal"
          />
        </van-tab>
      </van-tabs>
      <div class="float-btn" @click="onOpenTaskType">
        <img src="~@/assets/img/task/icon_add.png" />
      </div>
      <task-filter ref="refFilter" @on-change="onChangeFilter" />
      <task-type ref="refTaskType" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IconFilter from '@/assets/img/task/icon-finished.png'
import TaskList from './components/task-list.vue'
import TaskFilter from './components/task-filter.vue'
import TaskType from './components/task-type.vue'
import './constant'
export default {
  name: 'Task',
  components: {
    TaskList,
    TaskFilter,
    TaskType
  },
  data() {
    return {
      IconFilter,
      activeName: 0,
      taskTotal: {
        undo: 0,
        finished: 0,
        overdue: 0
      },
      filterParams: {}
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userAuth/getUsersCurrentInfo'
    }),
    showPage() {
      const auth_type = global.APP_CONFIG.auth_type
      const flag =
        auth_type === 'customAuthLogin' ||
        ((auth_type === 'webAuthLogin' || auth_type === 'scanCodeAuthLogin') &&
          this.userInfo)
      return flag
    }
  },
  created() {
    this.tenantId = global.APP_CONFIG.tenantId
  },
  methods: {
    onChangeFilter(params) {
      this.filterParams = params
    },
    // 打开筛选 popup
    onOpenFilter() {
      this.$refs.refFilter.onOpen()
    },

    onOpenTaskType() {
      this.$refs.refTaskType.onOpen()
    },

    // tab 上记录总数
    onChangeTotal(data) {
      Object.assign(this.taskTotal, data)
    }
  }
}
</script>

<style lang="less">
@primary-color: #287de1;
.float-btn {
  width: 56px;
  height: 56px;
  position: fixed;
  bottom: 100px;
  right: 16px;
  text-align: center;
  line-height: 56px;
  background-color: #287de1;
  box-shadow: 0 3px 12px 0 rgba(40, 125, 225, 0.35);
  border-radius: 50%;
  img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }
}
.task-wrap /deep/ {
  height: 100%;
  overflow-y: auto;
  .tab-btn {
    height: 44px;
    line-height: 44px;
    padding: 0 0.3864rem;
    .icon-filter {
      width: 12.85px;
      height: 13.93px;
    }
  }

  .van-tab {
    font-size: 0.338rem;
    color: #999999;
  }
  .van-tabs {
    .van-tab--active {
      color: @primary-color;
    }
  }
}
</style>
