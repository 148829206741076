<template>
  <div class="task-item">
    <div class="task-title">
      任务名称：{{ taskDetail.taskForm == 2 ? '跟进' : taskDetail.taskName }}
    </div>
    <div class="task-content">
      <describeItem
        v-if="taskDetail.ext && taskDetail.ext.nickName"
        label="客户昵称"
        :content="taskDetail.ext && taskDetail.ext.nickName"
      />
      <describeItem label="任务内容" :content="taskDetail.taskDetail" />
      <describeItem label="任务执行人" :content="taskDetail.executorName" />
      <describeItem
        v-if="taskDetail.taskForm == 2"
        label="提醒时间"
        :content="getFormatDate(taskDetail.endTime)"
      />
      <!-- <describeItem
        label="要求完成时间"
        :content="getFormatDate(taskDetail.endTime)"
      /> -->
      <describeItem
        v-else-if="taskDetail.taskType == 1"
        label="要求完成时间"
        :content="getFormatDate(taskDetail.endTime)"
      />
      <describeItem v-else label="要求完成时间" :content="cmpFinishedTime" />
      <describeItem
        v-show="taskDetail.taskType === 2"
        label="任务截止时间"
        :content="getFormatDate(taskDetail.endTime)"
      />
    </div>
    <div class="item-footer">
      <div class="total" @click="onShowChild">
        <template v-if="cmpChildTaskList.length > 0">
          <status-text :text="`已完成(${cmpUndoTaskTotal.done})`" />
          <status-text :text="`未完成(${cmpUndoTaskTotal.undo})`" />
        </template>
      </div>
      <div class="item-btn">
        <van-button
          v-if="[12, 22].includes(taskDetail.taskStatus)"
          round
          type="info"
          class="normal-btn"
          @click="onUrgeTask(taskDetail.id)"
          >催办</van-button
        >
        <van-button
          v-if="[12, 22].includes(taskDetail.taskStatus)"
          round
          type="info"
          class="plain-btn"
          plain
          @click="onUpdateTask(taskDetail)"
          >修改</van-button
        >
        <van-button
          :icon="IconDelete"
          class="icon-btn"
          @click="onDelete(taskDetail.id)"
        />
      </div>
    </div>
    <van-list v-show="isShowChild" class="task-children">
      <van-cell v-for="(child, i) in cmpChildTaskList" :key="child.id">
        <div class="task-child-title">
          <span :class="getCurrentTaskStatus(child) ? 'status-color-1' : ''">
            子任务{{ i + 1 }}
            <span v-show="getCurrentTaskStatus(child)">（当前任务）</span>
          </span>
        </div>
        <img
          v-if="child.status === 1"
          src="~@/assets/img/task/icon-finished.png"
          class="seal-img"
        />
        <img
          v-if="child.status === 2"
          src="~@/assets/img/task/icon-expired.png"
          class="seal-img"
        />
        <describeItem
          label="任务要求时间"
          :content="getFormatDate(child.endTime)"
        />
        <describeItem label="完成状态">
          <span :class="`status-color-${child.scheduleStatus}`">
            {{ child.scheduleStatus | fltStatusText }}
          </span>
        </describeItem>
        <describeItem
          label="实际完成时间"
          :content="getFormatDate(child.finishTime)"
        />
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import { WEEK_DAYS, MONTH_DAYS } from '@/utils'
import moment from 'moment'
import describeItem from '@/components/describeItem'
import statusText from './status-text'
import IconDelete from '@/assets/img/task/icon_delete.png'
import { deleteTask, urgeTask } from '@/api/modules/task'

export default {
  name: 'TaskItem',
  components: { describeItem, statusText },
  filters: {
    fltStatusText(val) {
      switch (val) {
        case 11:
          return '未开始'
        case 12:
          return '未完成'
        case 13:
          return '已完成'
        case 22:
          return '过期未完成'
        case 23:
          return '过期已完成'
        default:
          return '--'
      }
    }
  },
  props: {
    taskDetail: {
      type: Object,
      default: () => null
    },
    taskStatus: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      IconDelete,
      isShowChild: false,
      taskContent: []
    }
  },

  computed: {
    // 子任务列表
    cmpChildTaskList() {
      if (Array.isArray(this.taskDetail.taskScheduleVoList)) {
        return this.taskDetail.taskScheduleVoList
      }
      return []
    },

    // 计算未完成、已完成子任务数量
    cmpUndoTaskTotal() {
      const data = { done: 0, undo: 0 }
      if (Array.isArray(this.taskDetail.taskScheduleVoList)) {
        this.taskDetail.taskScheduleVoList.forEach(task => {
          if ([13, 23].includes(task.scheduleStatus)) {
            data.done = data.done + 1
          }
          else {
            data.undo = data.undo + 1
          }
        })
      }
      return data
    },

    cmpTaskDeadline() {
      return '--'
    },

    cmpFinishedTime() {
      let text = ''
      const taskScheduleConfig = this.taskDetail.taskScheduleConfig
      if (taskScheduleConfig) {
        const {
          jobType,
          dayOfWeeks,
          dayOfMonths,
          hour,
          minute,
          second
        } = taskScheduleConfig
        if (jobType === 2) {
          const index = WEEK_DAYS.findIndex(w => w.value === dayOfWeeks[0])
          text += '每周 ' + WEEK_DAYS[index].label
        }
        else {
          const index = MONTH_DAYS.findIndex(w => w.value === dayOfMonths[0])
          text += '每月 ' + MONTH_DAYS[index].label
        }
        text += ` ${hour < 10 ? '0' + hour : hour}:${
          minute < 10 ? '0' + minute : minute
        }:${second < 10 ? '0' + second : second}`
      }
      return text
    }
  },

  methods: {
    onUpdateTask(task) {
      this.$router.push(
        `/task/create/${global.APP_CONFIG.tenantId}/${task.taskType}/${task.id}`
      )
    },

    getCurrentTaskStatus(task) {
      const currentTime = Date.now()
      const { startTime, endTime } = task
      if (
        startTime &&
        endTime &&
        currentTime >= startTime &&
        currentTime <= endTime && ![13, 23].includes(task.scheduleStatus)
      ) {
        return currentTime >= startTime && currentTime <= endTime
      }
      return false
    },

    // 切换子任务展示
    onShowChild() {
      this.isShowChild = !this.isShowChild
    },

    // 删除任务
    onDelete(taskId) {
      Dialog.confirm({
        title: '确定删除此任务？',
        message: '删除后，任务执行人的个人待办列表中将看不到此任务。',
        confirmButtonColor: '#287DE1'
      }).then(async() => {
        const params = {
          taskId,
          tenantId: global.APP_CONFIG.tenantId
        }
        const res = await deleteTask(params)
        if (res.code === 0) {
          this.$toast('删除成功')
          this.$emit('on-refresh')
        }
        else {
          this.$toast(res.msg)
        }
      })
    },

    async onUrgeTask(taskId) {
      const params = {
        taskId,
        tenantId: global.APP_CONFIG.tenantId
      }
      const res = await urgeTask(params)
      if (res.code === 0) {
        this.$toast('催办成功')
        this.$emit('on-refresh')
      }
      else {
        this.$toast(res.msg)
      }
    },

    // 格式化时间
    getFormatDate(val) {
      if (val) {
        return moment(Number(val)).format('YYYY-MM-DD HH:mm:ss')
      }
      return ''
    }
  }
}
</script>

<style lang="less" scoped>
.task-item {
  padding: 12px;

  .task-title {
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    padding: 0 0.338rem;
    margin-bottom: 16px;
  }

  .task-content {
    background: #f8f8f8;
    padding: 0.338rem;
  }

  .item-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-btn {
      .normal-btn {
        width: 59px;
        height: 24px;
        font-size: 14px;
        color: #ffffff;
        padding: 0;
        border-radius: 12px;
        margin-right: 8px;
      }
      .plain-btn {
        width: 61px;
        height: 24px;
        border-radius: 12px;
      }
      .icon-btn {
        border-width: 0;
        padding: 0 0 0 8px;
      }
    }
  }
  .task-children {
    /deep/ .van-cell__value {
      position: static;
    }
    .seal-img {
      position: absolute;
      right: -10px;
      bottom: -10px;
      width: 77px;
      width: 77px;
    }

    .status-color-0 {
      color: #f8ad00;
    }
    .status-color-1 {
      color: #287de1;
    }
    .task-child-title {
      font-weight: 500;
      font-size: 0.338rem;
      color: #333333;
      margin-bottom: 0.121rem;
    }

    .finish-button {
      padding: 0.06rem 0.519rem;
      background: #ffffff;
      border: 0.0241rem solid #287de1;
      border-radius: 0.29rem;
      color: #287de1;
      float: right;
    }
  }
}
</style>
