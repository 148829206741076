<template>
  <van-list
    v-model="loadStatus"
    class="task-list"
    :finished="finished"
    finished-text="没有更多了"
    @load="fetchTaskList"
  >
    <van-cell v-for="task in taskList" :key="task.id" class="task-cell">
      <task-item :task-status="taskStatus" :task-detail="task" @on-refresh="onRefresh" />
    </van-cell>
  </van-list>
</template>

<script>
import { mapGetters } from 'vuex'
import taskItem from './task-item.vue'
import { getTaskList } from '@/api/modules/task'

export default {
  name: 'TaskList',
  components: { taskItem },
  props: {
    taskStatus: {
      type: Number,
      default: 1
    },
    filterParams: {
      type: Object,
      default: () => ({})
    },
    active: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loadStatus: false,
      finished: false,
      taskList: [],
      isLoading: false,
      isRequest: false,
      params: {
        pageSize: 10,
        pageIndex: 1,
        taskStatus: '',
        tenantId: '',
        iamid: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userAuth/getUsersCurrentInfo'
    })
  },

  watch: {
    filterParams: {
      deep: true,
      handler(val) {
        Object.assign(this.params, val, { pageIndex: 1 })
        this.taskList = []
        this.params.pageIndex = 1
        this.loadStatus = false
        this.finished = false
        this.fetchTaskList()
      }
    }
  },

  created() {
    this.fetchTaskList()
  },
  methods: {
    // 加载任务列表
    async fetchTaskList() {
      if (this.isRequest) {
        return
      }
      this.isRequest = true
      const params = {
        ...this.params,
        taskStatus: this.taskStatus,
        tenantId: global.APP_CONFIG.tenantId,
        iamid: this.userInfo.id
      }
      const res = await getTaskList(params)
      this.isRequest = false

      this.loadStatus = false
      if (res.code === 0) {
        this.taskList.push(...res.data.dataList)
        const field = ['', 'undo', 'finished', 'overdue']
        this.$emit('change-total', {
          [field[this.taskStatus]]: res.data.page.totalCount
        })
        if (this.taskList.length >= res.data.page.totalCount) {
          this.finished = true
        }
        else {
          this.params.pageIndex = this.params.pageIndex + 1
        }
      }
    },

    onRefresh() {
      this.taskList = []
      this.params.pageIndex = 1
      this.loadStatus = false
      this.finished = false
      this.fetchTaskList()
    }
  }
}
</script>

<style lang="less" scoped>
@primary-color: #3173cd;
.task-list {
  padding: 0.3864rem;

  .task-cell {
    padding: 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    border-radius: 0.097rem;
    & + .task-cell {
      margin-top: 16px;
    }
  }
}

.status-wrap {
  padding: 0.29rem 0;
  line-height: initial;
  border-bottom: 0.0241rem solid #e8e8e8;
}
.van-cell::after {
  border-bottom-width: 0;
}
.finish-button-wrap {
  height: 0.483rem;
  margin-top: 0.217rem;
}
</style>
