<template>
  <van-popup
    v-model="isShowPopup"
    safe-area-inset-bottom
    position="bottom"
    class="input-popup"
    get-container="body"
    @closed="onClosed"
  >
    <div class="popup-title">
      <div class="btn cancel" @click="isShowPopup = false">取消</div>
      <div class="title">{{ cmpTitle }}</div>
      <div class="btn submit" @click="onSubmit">确定</div>
    </div>

    <div class="popup-body">
      <van-field
        v-model="content"
        rows="4"
        type="textarea"
        placeholder="请输入"
      />
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    inputType: {
      type: String,
      default: 'taskName'
    },
    inputContent: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isShowPopup: false,
      content: ''
    }
  },

  computed: {
    cmpTitle() {
      return this.inputType === 'taskName' ? '请输入任务名称' : '请输入任务内容'
    }
  },

  methods: {
    onSubmit() {
      const params = { [this.inputType]: this.content }
      this.$emit('on-change', params)
      this.isShowPopup = false
    },

    onOpen() {
      this.content = this.inputContent
      this.isShowPopup = true
    },

    onClosed() {
      this.content = ''
    }
  }
}
</script>

<style lang="less" scoped>
.input-popup {
  .popup-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    line-height: 54px;
    box-sizing: border-box;
    padding: 0 16px;
    background-color: #fff;
    z-index: 2;

    .title {
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #333333;
    }
    .btn {
      font-size: 14px;
      cursor: pointer;
      &.cancel {
        color: #999999;
      }
      &.submit {
        font-family: PingFangSC-Medium;
        color: #287de1;
      }
    }
  }

  .popup-body {
    padding: 0 27px 27px 27px;
    box-sizing: border-box;

    /deep/ .van-cell {
      background-color: #f5f5f5;
      border-radius: 4px;
    }
  }
}
</style>
