<template>
  <van-popup v-model="isShowPopup" class="calendar-popup">
    <div class="calendar-box">
      <van-calendar
        lazy-render
        allow-same-day
        color="#3173CD"
        type="range"
        :row-height="40"
        :show-mark="false"
        :min-date="minDate"
        :max-date="maxDate"
        :show-title="false"
        :poppable="false"
        :show-confirm="false"
        :default-date="defaultDate"
        @confirm="onConfirm"
        @select="onSelect"
      />
    </div>

    <div class="range-text">
      <span class="date" :class="{ valid: selectDate[0] }">
        {{ cmpStartDate }}
      </span>
      <span class="gap">至</span>
      <span class="date" :class="{ valid: selectDate[1] }">
        {{ cmpEndDate }}
      </span>
    </div>

    <div class="popup-footer">
      <a class="btn cancel" @click="isShowPopup = false">取消</a>
      <a class="btn submit" @click="onConfirm">确认</a>
    </div>
  </van-popup>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    type: {
      type: Number,
      default: 2 // 1 表示单个日期, 2 表示区间
    },
    minDate: {
      type: Date,
      default: () =>
        moment()
          .subtract(1, 'y')
          .toDate()
    },
    maxDate: {
      type: Date,
      default: () =>
        moment()
          .add(1, 'y')
          .toDate()
    },
    defaultDate: {
      type: [Date, Array],
      default: () => [new Date(), new Date()]
    }
  },
  data() {
    return {
      isShowPopup: false,
      selectDate: [new Date(), new Date()],
      timeStr: '00:00'
    }
  },

  computed: {
    cmpStartDate() {
      return this.selectDate && this.selectDate[0]
        ? moment(this.selectDate[0]).format('YYYY年M月D日')
        : '开始日期'
    },
    cmpEndDate() {
      return this.selectDate && this.selectDate[1]
        ? moment(this.selectDate[1]).format('YYYY年M月D日')
        : '结束日期'
    }
  },

  methods: {
    onConfirm() {
      const params = {
        start: new Date(this.selectDate[0]),
        end: new Date(this.selectDate[1])
      }
      this.$emit('on-change', params)
      this.isShowPopup = false
    },

    onSelect(date) {
      console.log('select', date)
      this.selectDate = date
    },
    onOpen() {
      this.isShowPopup = true
    }
  }
}
</script>

<style lang="less" scoped>
.calendar-popup {
  width: 277px;
  border-radius: 8px;
  /deep/ .custom-cell {
    .van-cell__title {
      color: #3173cd !important;
    }
  }

  .calendar-box {
    height: 360px;

    /deep/ .van-calendar__day {
      font-size: 14px;
      font-weight: normal;
      color: #566578;
      .van-calendar__selected-day {
        border-radius: 50%;
      }
      &.van-calendar__day--start,
      &.van-calendar__day--end,
      &.van-calendar__day--start-end {
        border-radius: 50%;
        color: #fff;
        .van-calendar__bottom-info {
          bottom: 2px;
          display: none;
        }
      }
      &.van-calendar__day--disabled {
        color: #c8c9cc !important;
      }
    }
  }
  .range-text {
    line-height: 48px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #f5f5f5;
    .date {
      flex: 1 1 auto;
      text-align: center;
      color: #999999;
      &.valid {
        color: #0088ff;
      }
    }
  }

  .popup-footer {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    border-top: 1px solid #f5f5f5;
    padding: 8px 0;
    .btn {
      height: 40px;
      flex: 0.5;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      &.cancel {
        color: #999999;
        border-right: 1px solid #f5f5f5;
      }
      &.submit {
        color: #287de1;
      }
    }
  }
}
</style>
